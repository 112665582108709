<template>
  <wide-panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        clearable
      />
    </v-toolbar>
    <v-alert
      :value="alert"
      type="error"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      item-key="registration"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.registration }}
          </td>
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td
            v-if="props.item.active === '1'"
            class="text-xs-center"
          >
            <v-icon color="success">
              mdi-check
            </v-icon>
          </td>
          <td
            v-else
            class="text-xs-center"
          >
            <v-icon color="error">
              mdi-close
            </v-icon>
          </td>
          <td class="text-xs-left">
            {{ props.item.dealer }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dealerMsisdn }}
          </td>
          <td class="text-xs-left">
            {{ props.item.dealerName }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </wide-panel>
</template>

<script>
import { createGetParams } from '@/rest'

const title = 'UGU List'

export default {
  components: {
    WidePanel: () => import(/* webpackChunkName: "widePanel" */ '@/components/WidePanel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      breadcrumbs: [
        {
          text: 'UGU', disabled: false, to: '/ugu',
        },
        {
          text: title, disabled: true,
        },
      ],
      headers: [
        {
          text: 'Registration',
          align: 'left',
          sortable: true,
          value: 'registration',
        },
        {
          text: 'UGU #',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'UGU Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Active',
          align: 'center',
          sortable: true,
          value: 'active',
        },
        {
          text: 'Dealer',
          align: 'left',
          sortable: true,
          value: 'dealer',
        },
        {
          text: 'Dealer Contact #',
          align: 'left',
          sortable: true,
          value: 'dealerMsisdn',
        },
        {
          text: 'Dealer Name',
          align: 'left',
          sortable: true,
          value: 'dealerName',
        },
      ],
      items: [],
      loading: false,
      search: null,
      title: title,
    }
  },
  mounted: function () {
    this.getUguList()
  },
  methods: {
    getUguList: function () {
      this.loading = true
      this.$rest.get('getUguList.php', createGetParams({}))
        .then(function (response) {
          this.items = response.data
          this.loading = false
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alert = true
          this.loading = false
        })
    },
  },
}
</script>
